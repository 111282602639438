import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../constants/api";
import AreaTop from "../components/AreaTop";
import Modal from "../components/Modal";

function Settings() {
  const [admin, setAdmin] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [newAdminData, setNewAdminData] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [registerError, setRegisterError] = useState(null);
  const [registerSuccess, setRegisterSuccess] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const token = localStorage.getItem("URJA_token");
        const response = await axios.get(`${BASE_URL}/admin/me`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setAdmin(response.data.data);
        setFormData({
          name: response.data.data.fullName,
          email: response.data.data.email,
          password: "",
        });
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewAdminChange = (e) => {
    setNewAdminData({
      ...newAdminData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.name) errors.name = "Name is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";

    return errors;
  };

  const validateNewAdminForm = (data) => {
    let errors = {};

    if (!data.fullName) errors.fullName = "Full Name is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setLoading(true); // Set loading to true before making the API call

    try {
      const token = localStorage.getItem("URJA_token");
      const response = await axios.put(`${BASE_URL}/admin/me`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setAdmin(response.data.data);
      alert("Details updated successfully");
      setFormData({
        name: formData.name,
        email: formData.email,
      }); 
      setFormErrors({});
      setLoading(false); 
    } catch (error) {
      setError(error.message);
      setLoading(false); 
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const errors = validateNewAdminForm(newAdminData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setLoading(true); 

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/admin/register`,
        newAdminData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setRegisterSuccess("New admin registered successfully");
      setNewAdminData({
        fullName: "",
        email: "",
        password: "",
      });
      setTimeout(() => {
        setIsModalOpen(false); // Close the modal after successful registration
        setRegisterSuccess(""); // reset the success message
      }, 1500);
      setFormErrors({});
      setLoading(false); // Set loading to false after successful submission
    } catch (error) {
      setRegisterError(error.message);
      setLoading(false); // Set loading to false in case of error
    }
  };

  if (loading)
    return (
      <>
        <AreaTop title={"Settings"} />
        <div className="flex items-center justify-center h-screen">
          Loading...
        </div>
      </>
    );
  if (error)
    return (
      <>
        <AreaTop title={"Settings"} />
        <div className="flex items-center justify-center h-screen">
          Error: {error}
        </div>
      </>
    );
  return (
    <>
      <AreaTop title={"Settings"} />
      <div className="flex items-center justify-center mt-8">
        <div className="bg-white shadow-md rounded-lg p-8 max-w-lg w-full">
          <h1 className="text-2xl font-bold mb-6 text-center">Admin Details</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              {formErrors.name && (
                <div className="text-red-500 text-sm">{formErrors.name}</div>
              )}
            </div>
            <div>
              <label className="block text-gray-700">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              {formErrors.email && (
                <div className="text-red-500 text-sm">{formErrors.email}</div>
              )}
            </div>
            <div>
              <label className="block text-gray-700">Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              {formErrors.password && (
                <div className="text-red-500 text-sm">
                  {formErrors.password}
                </div>
              )}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                Update Details
              </button>
            </div>
          </form>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              Add New Admin
            </button>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h1 className="text-2xl font-bold mb-6 text-center">
          Register New Admin
        </h1>
        <form onSubmit={handleRegisterSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Full Name:</label>
            <input
              type="text"
              name="fullName"
              value={newAdminData.fullName}
              onChange={handleNewAdminChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {formErrors.fullName && (
              <div className="text-red-500 text-sm">{formErrors.fullName}</div>
            )}
          </div>
          <div>
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              name="email"
              value={newAdminData.email}
              onChange={handleNewAdminChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {formErrors.email && (
              <div className="text-red-500 text-sm">{formErrors.email}</div>
            )}
          </div>
          <div>
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              name="password"
              value={newAdminData.password}
              onChange={handleNewAdminChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {formErrors.password && (
              <div className="text-red-500 text-sm">{formErrors.password}</div>
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              Register Admin
            </button>
          </div>
        </form>
        {registerError && (
          <div className="text-red-500 mt-4 text-center">{registerError}</div>
        )}
        {registerSuccess && (
          <div className="text-green-500 mt-4 text-center">
            {registerSuccess}
          </div>
        )}
      </Modal>
    </>
  );
}

export default Settings;
