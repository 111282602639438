import React, { useState, useEffect } from "react";
import AreaTop from "../components/AreaTop";
import DataCard from "../components/DataCard";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsMusicNoteList } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import DataTable from "../components/DataTable";
import { BASE_URL } from "../constants/api";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([
    {
      title: "All time Song Requests",
      icon: AiOutlineOrderedList,
      data: "0",
      color: "#23995e",
    },
    {
      title: "Today's Song Requests",
      icon: BsMusicNoteList,
      data: "0",
      color: "#0932b0",
    },
    {
      title: "Total Unique Users",
      icon: FaUsers,
      data: "0",
      color: "#ff0000",
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Song Name",
        accessor: "songName",
      },
      {
        Header: "Requested At",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const date = new Date(value);
          return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`;
        },
      },
    ],
    []
  );

  const [tableData, setTableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("URJA_token");

    fetch(`${BASE_URL}/song-request?page=${pageIndex + 1}&limit=${pageSize}`, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            window.location.href = "/login";
          }
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTableData(data.data);
        const propertyNames = ["totalRequests", "todayRequests", "uniqueUsers"];
        setData((prev) =>
          prev.map((item, index) =>
            propertyNames[index]
              ? { ...item, data: data[propertyNames[index]] }
              : item
          )
        );
        setTotalPages(Math.ceil(data.total / pageSize));
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }, [pageIndex, pageSize]);

  return (
    <>
      <AreaTop title={"Dashboard"} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 mt-4">
        {data.map((item, index) => (
          <DataCard
            key={index}
            title={item.title}
            icon={item.icon}
            data={item.data}
            color={item.color}
          />
        ))}
      </div>
      <div className="mt-4">
        {isLoading ? (
          <div className="flex items-center justify-center h-64 border-2 border-dashed border-gray-200 text-gray-500">
            <p className="text-xl">Loading...</p>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-64 border-2 border-dashed border-gray-200 text-gray-500">
            <p className="text-xl">Error: {error}</p>
          </div>
        ) : tableData.length === 0 ? (
          <div className="flex items-center justify-center h-64 border-2 border-dashed border-gray-200 text-gray-500">
            <p className="text-xl">No data available to display</p>
          </div>
        ) : (
          <>
            <DataTable data={tableData} columns={columns} />
            <div className="flex justify-between p-4">
              <button
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
                className="py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
              >
                Previous Page
              </button>
              <div className="py-2 px-4 font-semibold">
                Page{" "}
                <em>
                  {pageIndex + 1} of {totalPages}
                </em>
              </div>
              <button
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex >= totalPages - 1}
                className="py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
              >
                Next Page
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
